html,
body {
  font-family: 'Open Sans', sans-serif;
}

.btn-primary {
  background-color: #3498db;
  border-color: #3498db;
}

.bg-primary {
  background-color: #3498db !important;
}

.transcript-editor {
  border: 1px solid #ddd;
  padding: 5px 15px;
  cursor: text;
}

.transcript-editor-block {
  display: flex;
  margin: 10px 0px;
  line-height: 1.7;
}

.transcript-editor-speaker {
  width: 100px;
}

.transcript-editor-text {
  flex: 1;
}

.transcript-editor-entity__word {
  background-color: #d4eaff;
  border: 1px solid #ddd;
  padding: 0 2px;
}

.transcript-editor-span__unplayed {
  color: rgb(0, 0, 0, 0.3);
}

.transcript-editor-span__played {
  color: #000;
}
